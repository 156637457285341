import { IDatasetElement } from "../../../../types";

interface IProps {
  item: IDatasetElement;
}
const ListItemUrl = ({ item }: IProps) => {
  // Not in used
  return null;
};
export default ListItemUrl;
